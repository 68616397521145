import React, { Component } from 'react'
import styled from '@emotion/styled';
import { config, theme } from '@packages/fdpq-core/config/atc';
import { FdpqWidget } from '@packages/fdpq-widget';



const GlobalStyleProvider = styled.div`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Roboto Light, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
`;

const dealersMockData = {
  selectedDealers: [
    {
      DealerPosition: 1,
      FranchiseId: 3358779,
      ProfileId: 7686,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '69393847',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'ATC NISSAN OF SAN JUAN CAPISTRANO',
      VendorDealerAddress: '33633 CAMINO CAPISTRANO',
      VendorDealerCity: 'SAN JUAN CAPISTRANO',
      VendorDealerState: 'CA',
      VendorDealerZip: '92675',
    },
    {
      DealerPosition: 2,
      FranchiseId: 3358365,
      ProfileId: 7682,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '5656',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '319067ff-0a98-4483-952a-892764916ee9',
      VendorDealerName: 'Shift NISSAN OF IRVINE',
      VendorDealerAddress: '28802 Marguerite Pkwy',
      VendorDealerCity: 'Irvine',
      VendorDealerState: 'CA',
      VendorDealerZip: '92618',
    },
    {
      DealerPosition: 3,
      FranchiseId: 3358779,
      ProfileId: 7686,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '5707',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'Another ATC NISSAN OF TUSTIN',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Tustin',
      VendorDealerState: 'CA',
      VendorDealerZip: '92782',
    }
  ],
  dealerList: [
    {
      DealerPosition: 1,
      FranchiseId: 3358779,
      ProfileId: 7686,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '69393847',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'ATC NISSAN OF SAN JUAN CAPISTRANO',
      VendorDealerAddress: '33633 CAMINO CAPISTRANO',
      VendorDealerCity: 'SAN JUAN CAPISTRANO',
      VendorDealerState: 'CA',
      VendorDealerZip: '92675',
    },
    {
      DealerPosition: 2,
      FranchiseId: 3358365,
      ProfileId: 7682,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '5656',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '319067ff-0a98-4483-952a-892764916ee9',
      VendorDealerName: 'Shift NISSAN OF IRVINE',
      VendorDealerAddress: '28802 Marguerite Pkwy',
      VendorDealerCity: 'Irvine',
      VendorDealerState: 'CA',
      VendorDealerZip: '92618',
    },
    {
      DealerPosition: 3,
      FranchiseId: 3358779,
      ProfileId: 7686,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6033',
      VendorDealerId: '5707',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'Another ATC NISSAN OF TUSTIN',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Tustin',
      VendorDealerState: 'CA',
      VendorDealerZip: '92782',
    },
    {
      DealerPosition: 4,
      FranchiseId: 3358781,
      ProfileId: 7687,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5708',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN OF MONTCLAIR',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 5,
      FranchiseId: 3358781,
      ProfileId: 7687,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5709',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 5',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 6,
      FranchiseId: 3358782,
      ProfileId: 7688,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5710',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 6',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 7,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5711',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 7',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 8,
      FranchiseId: 3358784,
      ProfileId: 7690,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5712',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 8',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 9,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5713',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 9',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 10,
      FranchiseId: 3358784,
      ProfileId: 7789,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5714',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 10',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 11,
      FranchiseId: 3358784,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5715',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 11',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 12,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 12',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 13,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 13',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 14,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 14',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 15,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 15',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 16,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 16',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 17,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 17',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 18,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 18',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 19,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 19',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 20,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 20',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 21,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 21',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 22,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 22',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 23,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 23',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 24,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 24',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
    {
      DealerPosition: 25,
      FranchiseId: 3358783,
      ProfileId: 7689,
      TrackingCode: '58ac42ba-9bde-42ac-9e02-c3bee3ac6043',
      VendorDealerId: '5716',
      VendorDealerCode: null,
      VendorProgramId: null,
      VendorTransactionId: '',
      VendorDealerName: 'NISSAN number 25',
      VendorDealerAddress: '2925 Harbor Blvd.',
      VendorDealerCity: 'Monclair',
      VendorDealerState: 'CA',
      VendorDealerZip: '92762',
    },
  ],
}
export default class rhtest extends Component {
  render() {
    const name = 'carloss';
    const options = [
      {
        value: 'Nissan',
        text: 'Nissan'
      },
      {
        value: 'Subaru',
        text: 'Subaru'
      }
    ]
    return (
      <GlobalStyleProvider>
        <div>
          <FdpqWidget initialData={{
            theme, config, navigation: {
              currentPage: 'DEALER_SELECTOR'
            }, }} />
        </div>
      </GlobalStyleProvider>
    )
  }
}
